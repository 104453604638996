<template>
  <v-card :loading="isLoading">
    <v-card-title class="break-word-normal" primary-title>
      <div>
        <v-icon left>
          {{ $vuetify.icons.values.alert }}
        </v-icon>
        {{ $trans("event_deleting_title") }}
      </div>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1 font-weight-medium">
        <span>{{ $trans("selected_ids_label") }}: {{ getIds.join(", ") }}</span>
      </p>
      <p class="text-subtitle-1">
        {{ $trans("event_deleting_question") }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-checkbox
        v-model="skipNotifications"
        dense
        :disabled="isLoading"
        hide-details
      >
        <template #label>
          <span>{{ $trans("booking_deleted_no_notifications_label") }}</span>
        </template>
      </v-checkbox>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('cancel', false)">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="handleDeleteAction"
      >
        {{ $trans("delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";

export default {
  name: "RemoveEventsDialog",
  mixins: [sharedActions, eventActions],
  data() {
    return {
      isLoading: false,
      skipNotifications: false,
      bookingIds: null,
      events: null,
    };
  },
  computed: {
    getIds() {
      if (this.bookingIds) {
        return this.bookingIds;
      }

      return this.events.map((event) => event.id);
    },
  },
  created() {
    this.skipNotifications = this.getConfirmDialog.data.skipNotifications;
    this.bookingIds = this.getConfirmDialog.data.bookingIds;
    this.events = this.getConfirmDialog.data.events;
  },
  methods: {
    ...mapActions({
      deleteMultipleBooking: "booking/deleteMultiple",
    }),
    handleDeleteAction() {
      const bookingIds = this.bookingIds;
      const events = this.events;

      if (bookingIds) {
        this.deleteBookingIds(bookingIds);
      }

      if (events) {
        this.deleteEvents(events);
      }
    },
    async deleteEvents(events) {
      const { bookingIds, groupBookingIds, unavailableBookingSlotIds } =
        this.getEventIds(events);
      const requests = [];

      const mergedBookingIds = Array.from(
        new Set([...bookingIds, ...groupBookingIds]),
      );

      if (mergedBookingIds.length > 0) {
        pushEvent("removeBookings");
        requests.push(
          this.deleteMultipleBookings({
            booking_ids: mergedBookingIds,
            skip_notifications: this.skipNotifications,
            reload: false,
          }),
        );
      }

      if (unavailableBookingSlotIds.length > 0) {
        pushEvent("removeUnavailableBookingSlots");
        requests.push(
          this.deleteMultipleUnavailableBookingSpot({
            ids: unavailableBookingSlotIds,
          }),
        );
      }

      try {
        this.isLoading = true;
        await Promise.all(requests);
        this.handleSuccess();
      } catch (error) {
        this.handleFail(error);
      }
    },
    deleteBookingIds(bookingIds) {
      this.isLoading = true;

      this.deleteMultipleBooking({
        booking_ids: bookingIds,
        skip_notifications: this.skipNotifications,
        reload: false,
      })
        .then(() => {
          this.handleSuccess();
        })
        .catch((error) => {
          this.handleFail(error);
        });
    },
    handleSuccess() {
      this.isLoading = false;
      this.$emit("close", true);
      this.$root.$emit("closeEventPreviewModal", true);
    },
    handleFail(error) {
      errorNotification(null, error, false);
      this.isLoading = false;
    },
  },
};
</script>
